import api from './../AxiosConfig';
import axios from 'axios';

export const checkGrammar = async (paragraph) => {
    try {
        const response = await axios.post(
            'https://trinka-grammar-checker.p.rapidapi.com/v2/para-check/en',
            {
                paragraph: paragraph,
                language: 'US'
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'x-rapidapi-host': 'trinka-grammar-checker.p.rapidapi.com',
                    'x-rapidapi-key': '94575b5ffdmsh29843acc2d55c3fp1f8f7cjsn1365bc9c96a1',
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error checking grammar:', error);
        throw error;
    }
};

export const AnalysisService = async (text) => {
    try {
        const response = await api.post('/evaluate_text', { text });
        if (response.status === 200) {
            return response.data;
        } else {
            return null;
        }
    } catch (error) {
        if (error.code === 'ECONNREFUSED') {
            throw new Error("It seems there's a network issue. Please check your internet connection.");
        }
        console.error('Error making the request:', error);
        return null;
    }
};

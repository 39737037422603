import { useState } from "react";
import { UserRegisterRequestDTO } from "../../models/User";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { register, registerSubscriber } from "../../services/UserService";

const RegisterFreeAccountPage = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [formData, setFormData] = useState(new UserRegisterRequestDTO());
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setErrorMessage('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await registerSubscriber(formData);
        console.log(response);
        if (response['status_code'] === 200) {
            alert('Account created successfully');
            window.location.href = '/dashboard';
        } else {
            alert(response["message"])
        }

    }
    return (
        <div>
            <div className="py-2 sm:px-4">
                <div className="mt-4 lg:px-12 sm:px-4 md:px-6">
                    <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
                        <div className='flex flex-col gap-4'>
                            <p style={{ fontFamily: 'Red Hat Display' }} className='text-2xl'>Register</p>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <div className='flex flex-col gap-2 mt-2'>
                                <div className="flex flex-row gap-4">
                                    <div className="flex flex-col w-1/2 gap-2">
                                        <p className='text-sm'>First Name</p>
                                        <input className="px-4 py-2 mb-2 border border-gray-300 rounded-md" type="text" placeholder="John" name="firstname" value={formData.firstname} onChange={handleChange} />
                                    </div>
                                    <div className="flex flex-col w-1/2 gap-2">
                                        <p className='text-sm'>Last Name</p>
                                        <input className="px-4 py-2 mb-2 border border-gray-300 rounded-md" type="text" placeholder="Doe" name="lastname" value={formData.lastname} onChange={handleChange} />
                                    </div>
                                </div>
                                <p className='text-sm' >Email</p>
                                <input className="w-full px-4 py-2 mb-2 border border-gray-300 rounded-md" type="email" placeholder="john@email.com" name="email" value={formData.email} onChange={handleChange} />
                            </div>
                            <div className='flex flex-col gap-4'>
                                <p className='text-sm'>Password</p>
                                <div className="relative flex items-center">
                                    <input
                                        id="password"
                                        className="w-full px-4 py-2 pr-10 mb-2 border border-gray-300 rounded-md"
                                        type={showPassword ? "text" : "password"}
                                        placeholder="********"
                                        name="password"
                                        value={formData.password} onChange={handleChange}
                                    />
                                    <button
                                        type="button"
                                        className="absolute inset-y-0 right-0 flex items-center mb-2 mr-2 orange "
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <EyeOffIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5 " />}
                                    </button>
                                </div>
                            </div>
                            <div className='flex flex-col gap-4'>
                                <p className='text-sm'>Confirm Password</p>
                                <div className="relative flex items-center">
                                    <input
                                        id="password"
                                        className="w-full px-4 py-2 pr-10 mb-2 border border-gray-300 rounded-md"
                                        type={showConfirmPassword ? "text" : "password"}
                                        placeholder="********"
                                        name="confirmPassword"
                                        value={formData.confirmPassword} onChange={handleChange}
                                    />
                                    <button
                                        type="button"
                                        className="absolute inset-y-0 right-0 flex items-center mb-2 mr-2 orange "
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    >
                                        {showConfirmPassword ? <EyeOffIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5 " />}
                                    </button>
                                </div>
                            </div>
                            <div className="flex justify-center mt-6">
                                <button className="px-4 py-2 text-sm text-white rounded-md sm:w-32 bg-orange" type="submit">Register</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );

}

export default RegisterFreeAccountPage;
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getPaymentMethods } from "../../services/PaymentService";
import { UserIcon, PhoneIcon } from "@heroicons/react/outline";
import UserContext from '../../context/UserContext';
import '../../pages/css/Main.css';
import api from './../../AxiosConfig';
import Logo from "../../widget/Logo";
import { FiInfo } from "react-icons/fi";
import FooterLight from "../../widget/FooterLight";

const PaymentFormPage = () => {
    const location = useLocation();
    const { user, loading } = useContext(UserContext);
    const { subscription_type_id, subscriptionName, price, currency } = location.state || {};
    const [name, setName] = useState(`${user.firstname} ${user.lastname}`);
    const [phone, setPhone] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (name === "" || phone === "") {
            setErrorMessage("All fields must be filled out.");
            return;
        }
        try {
            const response = await api.post('/payment-request', {
                subscription_type_id: subscription_type_id.toString(),
                name: name,
                phone_number: phone,
            });
            const { url } = response.data;

            if (url) {
                window.location.href = url;
            } else {
                console.error("No URL found in the response.");
            }
        } catch (error) {
            console.error("Error making payment request:", error);
        }
    }

    const ErrorMessage = ({ message }) => {
        return (
            <div className="relative flex flex-row gap-2 px-4 py-3 text-xs text-red-700 bg-red-100 border border-red-400 rounded" role="alert">
                <FiInfo className="inline-block mr-2 align-middle" />
                <span className="inline-block align-middle">{message}</span>
            </div>
        );
    };
    return (
        <div>
            <div className="relative h-screen py-2 page-container sm:px-4">
                <div className="flex justify-center px-4 py-2 mt-6 page-container">
                    <div className="flex flex-col max-w-lg gap-6 p-6 px-20 py-12 bg-white border border-gray-200 shadow-lg rounded-xl">
                        <div className="information">
                            <p style={{ fontFamily: "Red Hat Display" }} className="text-lg">You are paying</p>
                            <p className="text-4xl font-bold orange">{price} {currency}</p>
                            <p className="mt-4 font-bold text-md">Plan: {subscriptionName}</p>
                        </div>

                        <div className="flex flex-col gap-2 personal-information">
                            <p style={{ fontFamily: "Red Hat Display" }} className="text-lg">Personal Information</p>
                            {errorMessage && <ErrorMessage message={errorMessage} />}
                            <p className="text-sm">Name</p>
                            <div className="relative flex items-center">
                                <button type="button" className="absolute left-0 flex items-center mb-2 ml-3 orange">
                                    <UserIcon className="w-5 h-5" />
                                </button>
                                <input
                                    id="name"
                                    className="w-full px-4 py-4 pl-10 mb-2 text-sm border border-gray-300 rounded-md"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>

                            <p className="text-sm">Phone Number</p>
                            <div className="relative flex items-center">
                                <button type="button" className="absolute left-0 flex items-center mb-2 ml-3 orange">
                                    <PhoneIcon className="w-5 h-5" />
                                </button>
                                <input
                                    id="phone"
                                    className="w-full px-4 py-4 pl-10 mb-2 text-sm border border-gray-300 rounded-md"
                                    placeholder="+65 1111 1111"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </div>

                            <button onClick={handleSubmit} className="py-3 mt-4 text-white rounded-lg bg-orange">
                                <p>Proceed</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="background-image">
                <FooterLight />
            </div>
        </div>

    );
};

export default PaymentFormPage;

import { useState, useEffect } from 'react';
import PricingCard from '../../widget/PricingCard';
import { GetSubscription } from '../../services/SubscriptionService';
import FooterLight from '../../widget/FooterLight';
import api from '../../AxiosConfig';

const PaymentPlanPage = () => {
    const [subscriptionTypes, setSubscriptionTypes] = useState([]);
    const [stripePublicKey, setStripePublicKey] = useState(null);

    useEffect(() => {
        const fetchSubscriptionTypes = async () => {
            try {
                const data = await GetSubscription();
                if (data && data.Data) {
                    setSubscriptionTypes(data.Data);
                }
            } catch (error) {
                console.error('Error fetching subscription types:', error.message);
            }
        };

        const fetchStripePublicKey = async () => {
            try {
                const response = await api.get('/payment-config');
                console.log('Stripe public key:', response.data['publishable_key']);
                setStripePublicKey(response.data['publishable_key']);

            } catch (error) {
                console.error('Error fetching Stripe public key:', error.message);
            }
        };

        fetchSubscriptionTypes();
        fetchStripePublicKey();

        // Initialize Google Analytics
        const loadGoogleAnalytics = () => {
            const script = document.createElement('script');
            script.src = `https://www.googletagmanager.com/gtag/js?id=G-Z10ME8VFN7`;
            script.async = true;
            document.head.appendChild(script);

            script.onload = () => {
                window.dataLayer = window.dataLayer || [];
                function gtag() {
                    window.dataLayer.push(arguments);
                }
                gtag('js', new Date());
                gtag('config', 'G-Z10ME8VFN7');
            };
        };

        loadGoogleAnalytics();

    }, []);

    return (
        <div>
            <div className="relative h-screen py-2 page-container sm:px-4">
                <div className="flex flex-col sm:gap-6 lg:mt-24 md:flex-row md:items-center lg:px-32 sm:px-6 md:px-12 sm:mt-8">
                    <div className='flex flex-col gap-4 lg:w-2/3 md:mt-20'>
                        {subscriptionTypes.map(subscription => (
                            <div key={subscription._id} className="mt-auto underline lg:text-3xl md:text-3xl sm:text-xl orange" style={{ fontFamily: 'Red Hat Display' }}>
                                {subscription.subscription_name}
                            </div>
                        ))}
                        {subscriptionTypes.map(subscription => (
                            <p key={subscription._id} className="lg:text-base md:text-base sm:text-sm md:w-4/5">
                                {subscription.description}
                                <br />
                                <br />
                                Subscription expires on <b>31 December 2024</b>.
                            </p>
                        ))}
                    </div>

                    <div className="md:w-2/3 md:mt-0 sm:px-8 md:px-16">
                        {subscriptionTypes.map(subscription => (
                            <PricingCard
                                key={subscription._id}
                                subscription_type_id={subscription._id}
                                subscriptionName={subscription.subscription_name}
                                price={subscription.price}
                                currency={subscription.currency}
                                features={subscription.features}
                                stripePublicKey={stripePublicKey}
                            />
                        ))}

                    </div>
                </div>
            </div>
            <div className="background-image">
                <FooterLight />
            </div>
        </div>
    );
}

export default PaymentPlanPage;

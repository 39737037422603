import Logo from "../../widget/Logo";
import Password from "../../assets/images/password.png";
import ContactUsButton from "../../widget/ContactUsButton";
import FooterLight from "../../widget/FooterLight";

const SuccessForgetPasswordPage = () =>{
    return(
        <div>
            <div className="page-container relative h-screen py-2 sm:px-4">
            <div className='lg:px-12 lg:py-6 sm:p-4 md:p-8 flex flex-row justify-between'>
                <Logo/>
            </div>
            <div className="lg:px-12 mt-12 sm:px-4 md:px-6 flex flex-col justify-center items-center text-center">
                
                <img src={Password} alt="Email Sent" className="w-40 sm:w-24 lg:w-36"/>
                <p style={{ fontFamily: "Red Hat Display" }} className="mt-4 text-bold lg:text-2xl md:text-xl sm:text-xl">
                    Your password has been reset.  
                </p>

                <div className="subtitle lg:text-base mt-6 lg:px-20 sm:px-4 md:px-6 sm:text-sm md:text-sm">
                    <p>Go to <a href="/login"><span className="orange underline">login</span></a> page now!</p>
                </div>
                
            </div>


            </div>
            <div className="background-image">
                <FooterLight/>
            </div>
        </div>
        
    )
}

export default SuccessForgetPasswordPage;
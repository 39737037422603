import React, { useContext, useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import UserContext from '../context/UserContext';
import ProfileLoadingSkeleton from '../loading/ProfileLoadingSkeleton';
import MobileProfileSkeleton from '../loading/MobileProfileSkeleton';

const UserRoute = ({ children }) => {
  const { user, loading } = useContext(UserContext);

  const ResponsiveProfileSkeleton = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile ? <MobileProfileSkeleton /> : <ProfileLoadingSkeleton />;
  };

  if (loading) {
    return <ResponsiveProfileSkeleton />;
  }

  if (!user) {
    return <Navigate to="/register" replace />;
  }

  return user ? children : <Navigate to="/" replace />;
};

export default UserRoute;
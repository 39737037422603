import Logo from "../../widget/Logo";
import SentEmail from "../../assets/images/email-sent.png";
import ContactUsButton from "../../widget/ContactUsButton";
import FooterLight from "../../widget/FooterLight";

const SentEmailConfirmationPage = () =>{
    return(
        <div>

            <div className="page-container relative h-screen py-2 sm:px-4">
                <div className='lg:px-12 lg:py-6 sm:p-4 md:p-8 flex flex-row justify-between'>
                    <Logo/>
                </div>
                <div className="lg:px-12 mt-4 sm:px-4 md:px-6 flex flex-col justify-center items-center text-center">
                    
                    <img src={SentEmail} alt="Email Sent" className="w-40 sm:w-24 lg:w-36"/>
                    <p style={{ fontFamily: "Red Hat Display" }} className="text-bold lg:text-2xl md:text-xl sm:text-xl">
                        Email Sent! Check your inbox.   
                    </p>

                    <div className="subtitle lg:text-base mt-6 lg:px-20 sm:px-4 md:px-6 sm:text-sm md:text-sm">
                        <p>Finished resetting? Go to <a href="/login"><span className="orange underline">login</span></a> page now.</p>
                    </div>
                    
                </div>


            </div>
            <div className="background-image">
                <FooterLight/>
            </div>
        </div>
    )
}

export default SentEmailConfirmationPage;
import api from './../AxiosConfig';

export const login = async (formData) => {
    try {
        const response = await api.post('/login', formData, {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true 
        });
        return response.data
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const register = async (formData) => {
    try {
        const response = await api.post('/register', formData, {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true 
        });
        return response.data
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export const registerSubscriber = async (formData) => {
    try {
        const response = await api.post('/register-subscriber', formData, {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true 
        });
        return response.data
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export const editProfile = async (formData) => {
    try {
        const response = await api.post('/update-profile', formData, {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true 
        });
        return response.data
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export const changePassword = async (formData) =>{
    try {
        const response = await api.post('/change-password', formData, {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true 
        });
        return response.data
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export const resetPassword = async (token, formData) => {
    try {
        const response = await api.post(`/reset-password/${token}`, formData);
        return response.data;
    } catch (error) {
        console.error("Error changing password:", error);
        throw error;
    }
};



export const forgetPasswordRequest = async (formData) => {
    try{
        
        const response = api.post('/forget-password', formData, {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true 
        });
        return response
    }catch (error){
        console.error('Error:', error);
        throw error;
    }
}
// export const getCookie = (name) => {
//     const cookies = document.cookie.split(';');
//     for (let i = 0; i < cookies.length; i++) {
//         const cookie = cookies[i].trim();
//         if (cookie.startsWith(name + '=')) {
//             return cookie.substring(name.length + 1);
//         }
//     }
//     return null;
// };

// export const decodeAccessToken = () => {
//     const accessToken = getCookie('access_token');
//     if (accessToken) {
//         try {
//             const decodedToken = jwtDecode(accessToken);
//             return decodedToken;
//         } catch (error) {
//             console.error('Error decoding token:', error);
//             return null;
//         }
//     } else {
//         console.error('Access token not found in cookie');
//         return null;
//     }
// }
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CustomCalendar = ({ onDateChange }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleApplyClick = () => {
        if (startDate && endDate) {
            onDateChange(startDate, endDate);
        }
    };

    return (
        <div className=" mt-4 px-4 bg-white pb-4">
            <div className="flex flex-row gap-2">
                <div className="mb-4">
                    <label className="block mb-2 text-xs">Start Date</label>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        className="block w-full px-2 py-1 text-gray-700 border rounded text-xs"
                        placeholderText="Select start date"
                    />
                </div>
                <div className="mb-4">
                    <label className="block mb-2 text-xs">End Date</label>
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        className="block w-full px-2 py-1 text-gray-700 border rounded text-xs"
                        placeholderText="Select end date"
                    />
                </div>
            </div>
            <button
                onClick={handleApplyClick}
                className="w-full px-4 py-2 text-xs text-white  bg-orange rounded"
            >
                Apply
            </button>
        </div>
    );
};

export default CustomCalendar;


import React, { useContext } from 'react';
import UserContext from '../context/UserContext';
import "../pages/css/Main.css"

const UserWidget = () => {
  const { user, loading } = useContext(UserContext);
  const navigateProfile = () => {
    window.location.href = '/profile-page';
  }
  if (loading) {
    return <div></div>;
  }

  if (!user) {
    return <div></div>;
  }

  return (
    <div onClick={navigateProfile}  className='bg-orange-100 rounded-md lg:py-2 lg:px-4 sm:px-2 sm:py-2 md:px-2 md:py-2 flex flex-row gap-2 items-center'>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#EB5527" className="lg:size-6 sm:size-4 md:size-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
      </svg>
      <p className='lg:text-sm sm:text-sm md:text-sm orange font-bold'>{user.firstname} {user.lastname}</p>
    </div>
  );
};

export default UserWidget;

import { useParams } from "react-router-dom";
import FooterLight from "../../widget/FooterLight";
import Logo from "../../widget/Logo";
import { useEffect } from "react";
import api from "../../AxiosConfig";
import EmailVerify from "../../assets/images/password.png";

const VerifyEmailPage = () => {
    const { token } = useParams();

    useEffect(() => {
        const checkToken = async () => {
            try {
                const response = await api.get(`/check-verify-email-token/${token}`);
                if (response.data.status_code !== 200) {
                   window.location.href = '/token-email-verification-expired';    
                }
            } catch (error) {
                window.location.href = '/token-email-verification-expired';
            }
        };
        checkToken();
    }, [token]);

    const handleVerifyClick = async () => {
        try {
            const response = await api.post(`/verify-account/${token}`);
            if (response.data.status_code === 200) {
                window.location.href = '/account-verified';
            } else {
                console.error('Verification failed');
            }
        } catch (error) {
            console.error('Error during verification:', error);
        }
    };

    return (
        <div>
            <div className="page-container relative h-screen py-2 sm:px-4">
                <div className='lg:px-12 lg:py-6 sm:p-4 md:p-8 flex flex-row justify-between'>
                    <Logo />
                </div>
                <div className="lg:px-12 mt-12 sm:px-4 md:px-6 flex flex-col justify-center items-center text-center">
                    <img src={EmailVerify} alt="Email Sent" className="w-36 sm:w-24 lg:w-32" />
                    <p style={{ fontFamily: "Red Hat Display" }} className="text-bold lg:text-2xl md:text-xl sm:text-xl mt-4">
                        Verify Account
                    </p>
                    <button
                        className="mt-8 sm:w-48 bg-black px-4 py-3 text-white rounded-md text-sm"
                        onClick={handleVerifyClick}
                    >
                        <b>Verify</b>
                    </button>
                </div>
            </div>
            <div className="background-image">
                <FooterLight />
            </div>
        </div>
    );
};

export default VerifyEmailPage;

import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const MobileProfileSkeleton = () => {
    return (
        <div className="p-4">
            <h1 className="mb-4 text-2xl font-bold">
                <Skeleton width={100} />
            </h1>
            <div className='flex gap-4'>
                <div className="flex items-center justify-between mb-4" >
                    <Skeleton width={80} />
                </div>
                <div className="flex items-center justify-between mb-4" >
                    <Skeleton width={120} />
                </div>
                <div className="flex items-center justify-between mb-4" >
                    <Skeleton width={120} />
                </div>
            </div>

            <div className="flex items-center mb-6">
                <Skeleton circle width={64} height={64} className="mr-4" />
                <div>
                    <Skeleton width={120} className="mb-2" />
                    <Skeleton width={150} />
                </div>
            </div>

            <h2 className="mb-4 text-lg font-semibold">
                <Skeleton width={180} />
            </h2>

            <div className="mb-6 space-y-4">
                {[...Array(4)].map((_, index) => (
                    <div key={index}>
                        <Skeleton width={100} className="mb-1" />
                        <Skeleton height={36} />
                    </div>
                ))}
            </div>

            <Skeleton height={40} className="w-full" />

            <div className="mt-8 text-center">
                <Skeleton width={200} className="mx-auto mb-2" />
                <Skeleton width={150} height={36} className="mx-auto" />
            </div>

            <div className="mt-4 text-center">
                <Skeleton width={250} className="mx-auto" />
            </div>
        </div>
    );
};

export default MobileProfileSkeleton;
import FooterLight from "../../widget/FooterLight";
import SentEmail from "../../assets/images/email-sent.png";
import Logo from "../../widget/Logo";
import api from "../../AxiosConfig";
const ResendEmailVerificationPage = () => {
    const resendEmail = () =>{
        api.post("/request-verification", {})
        .then(response => {
            window.location.href = '/verify-account';
        })
    }
    return(
        <div>
            <div className="page-container relative h-screen py-2 sm:px-4">
                <div className='lg:px-12 lg:py-6 sm:p-4 md:p-8 flex flex-row justify-between'>
                    <Logo/>
                </div>
                <div className="lg:px-12 mt-4 sm:px-4 md:px-6 flex flex-col justify-center items-center text-center">
                    
                    <img src={SentEmail} alt="Email Sent" className="w-40 sm:w-24 lg:w-36"/>
                    <p style={{ fontFamily: "Red Hat Display" }} className="text-bold lg:text-2xl md:text-xl sm:text-xl">
                        Verify Your Account!
                    </p>

                    <div className="subtitle lg:text-base mt-6 lg:px-20 sm:px-4 md:px-6 sm:text-sm md:text-sm lg:w-3/5">
                        <p>You can request a new email verification link
                             <button className="text-orange underline" onClick={() => resendEmail()}>here</button>.
                        </p>
                    </div>

                    
                </div>


            </div>
            <div className="background-image">
                <FooterLight/>
            </div>
        </div>
    )
}

export default ResendEmailVerificationPage;
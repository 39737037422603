import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ProfileLoadingSkeleton = () => {
    return (
        <div className='m-16'>
            <h1 className="mb-4 text-2xl font-bold">
                <Skeleton width={150} />
            </h1>
            <div className='flex'>
                <div>
                    <div className="mb-4 text-2xl font-bold">
                        <Skeleton width={150} />
                    </div>
                    <div className="mb-4 text-2xl font-bold">
                        <Skeleton width={150} />
                    </div>
                    <div className="mb-4 text-2xl font-bold">
                        <Skeleton width={150} />
                    </div>
                </div>
                <div className="p-8 mx-20">
                    <h1 className="mb-4 text-2xl font-bold">
                        <Skeleton width={150} />
                    </h1>
                    <div className="mb-6">
                        <Skeleton circle width={80} height={80} className="mb-2" />
                        <Skeleton width={200} />
                    </div>
                    <h2 className="mb-4 text-xl font-semibold">
                        <Skeleton width={180} />
                    </h2>
                    <div className="space-y-4">
                        {[...Array(4)].map((_, index) => (
                            <div key={index} className="flex">
                                <Skeleton width={100} className="mr-4" />
                                <Skeleton width={200} />
                            </div>
                        ))}
                    </div>
                    <div className="mt-6">
                        <Skeleton width={120} height={40} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileLoadingSkeleton;
import React from 'react';
import FooterOrange from '../widget/FooterOrange';
import Navbar from '../widget/Navbar';

const MainLayout = ({ children }) => {
    return (
        <div className="flex flex-col min-h-screen">
            <Navbar />
            <div className="flex-grow">
                {children}
            </div>
            <FooterOrange />
        </div>
    );
};

export default MainLayout;
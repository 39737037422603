import React from "react";
import ActivityTable from "./widgets/ActivityTable";
import Sidebar from "./widgets/Sidebar";

const DashboardPage = () => {
  const handleRegisterAccount = () => {
    window.location.href = "/register-subscriber";
  };

  // Dummy data for usernames and emails
  const users = [
    { username: "johndoe", email: "johndoe@example.com" },
    { username: "janedoe", email: "janedoe@example.com" },
    { username: "adminuser", email: "admin@example.com" },
  ];

  return (
    <div className="mb-8">
      <Sidebar />
      <div className="py-2 ml-64 sm:px-4">
        <div className="mt-4 lg:px-12 sm:px-4 md:px-6">
          <h1
            className="text-2xl font-bold mb-6"
            style={{ fontFamily: "Red Hat Display" }}
          >
            Admin's Dashboard
          </h1>
          <div className="flex flex-col gap-2 mt-4">
            <h2 className="text-lg font-bold">User Activities</h2>
            <p className="text-gray-600">
              View and filter activities in CONSOLE-D.
            </p>
            <ActivityTable />
          </div>
          <div className="flex flex-col w-full md:w-2/5 gap-2 mt-8">
            <h2 className="text-lg font-bold">Register free account</h2>
            <p className="text-gray-600">
              Create new user accounts while simultaneously assigning them to
              predefined subscription plans.
            </p>
            <button
              className="w-48 px-4 py-2 mt-4 font-bold text-white rounded-md bg-orange text-sm hover:bg-opacity-90 transition-colors duration-200"
              onClick={handleRegisterAccount}
            >
              Register account
            </button>
          </div>

          {/* Display dummy user data */}
          <div className="mt-8">
            <h2 className="text-lg font-bold mb-4">Subscribed Users</h2>
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Username
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Email
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {users.map((user, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {user.username}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.email}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;

import React from 'react';
import api from '../AxiosConfig';

const PricingCard = ({subscription_type_id, subscriptionName, price, currency, features, stripePublicKey}) => {

  

  const handleStartPlanClick = async () => {
    if (stripePublicKey) {
      console.log('Stripe public key is available:', stripePublicKey);
      const token = localStorage.getItem('jwtToken');
      try {
        const response = await api.post("/checkout-session", 
          {
            subscription_id: subscription_type_id
          },
          {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (window.gtag) {
          window.gtag('event', 'begin_checkout', {
            event_label: "Begin Checkout",
            subscription_id: subscription_type_id,
            value: price,
            currency: currency,
          });
        }

        window.location.href = response.data.url;
      } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
      }
  
      
    } else {
      console.error('Stripe public key is not available');
    }
  
};

  return (
    <div className="max-w-sm mx-auto lg:py-16 md:py-16 sm:py-12 lg:px-12 md:px-12 sm:px-8 bg-light-orange rounded-3xl border-orange flex flex-col gap-2">
      <h2 style={{ fontFamily: 'Red Hat Display' }} className="orange mb-2 lg:text-sm md:text-sm sm:text-xs text-center">Valid till end of 2024</h2>
      <p style={{ fontFamily: 'Red Hat Display' }} className="orange mb-4 lg:text-6xl md:text-3xl sm:text-4xl font-bold  text-center">{currency} {price}</p>
      <ul className="orange mb-6 space-y-2 lg:text-sm md:text-sm sm:text-xs align-middle font-semibold ml-6 mr-6">
        {features.map((feature, index) => (
          <li key={index}>✔️ {feature}</li>
        ))}
      </ul>
      <button onClick={handleStartPlanClick} style={{ fontFamily: 'Red Hat Display' }} className="bg-orange text-white lg:text-md md:text-sm sm:text-sm py-2  rounded-md hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-rose-600 focus:ring-opacity-50">
        Start Plan
      </button>
    </div>
  );
};

export default PricingCard;

import React, { useEffect, useState } from 'react';
import MaterialTable from '@material-table/core';
import axios from 'axios';
import api from '../../../AxiosConfig';

const ActivityTable = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/get-all-activities');
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  const getRoleStyle = (role) => {
    let color;

    switch (role.toLowerCase()) {
      case 'admin':
        color = 'purple';
        break;
      case 'subscriber':
        color = 'darkorange';
        break;
      case 'free user':
        color = 'gray';
        break;
      default:
        color = 'black';
    }

    return {
      color
    };
  };
  return (
    <MaterialTable
      title=" "
      columns={[
        {
          title: 'Activity',
          field: 'activity_name',
          cellStyle: { fontSize: '0.875rem' },
          headerStyle: { fontFamily: 'Plus Jakarta Sans, sans-serif' } 
        },
        {
            title: 'Description',
            field: 'activity_description',
            cellStyle: { fontSize: '0.875rem' },
            headerStyle: { fontFamily: 'Plus Jakarta Sans, sans-serif' } 
        },
        {
          title: 'User\'s Email',
          field: 'email',
          cellStyle: { fontSize: '0.875rem' }, 
          headerStyle: { fontFamily: 'Plus Jakarta Sans, sans-serif' } 
        },
        {
            title: 'User\'s Role',
            field: 'role',
            render: rowData => (
              <div style={getRoleStyle(rowData.role)}>
                {rowData.role.charAt(0).toUpperCase() + rowData.role.slice(1)} {/* Capitalize the role */}
              </div>
            ),
            cellStyle: { fontSize: '0.875rem' }, 
            headerStyle: { fontFamily: 'Plus Jakarta Sans, sans-serif' }
          },
        {
          title: 'Date',
          field: 'created_at',
          render: rowData => new Date(rowData.created_at).toLocaleDateString('en-GB'),
          cellStyle: { fontSize: '0.875rem' },
          headerStyle: { fontFamily: 'Plus Jakarta Sans, sans-serif' }
        },
        {
          title: 'Time',
          field: 'created_at',
          render: rowData => {
            const date = new Date(rowData.created_at);
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            return `${hours}:${minutes} SGT`;
          },
          cellStyle: { fontSize: '0.875rem' },
          headerStyle: { fontFamily: 'Plus Jakarta Sans, sans-serif' }
        },
      ]}
      data={data}
      options={{
        sorting: true,
        search: true,
        paging: true,
        rowStyle: { border: '1px' }, 
        headerStyle: { fontFamily: 'Plus Jakarta Sans, sans-serif' }, 
        searchFieldStyle: { fontSize: '0.875rem' } 
      }}
      style={{
        boxShadow: 'none', 
        border: '1px solid lightgray', // Add light gray border around the table
        borderRadius: '8px', // Rounded corners for the table
        overflow: 'hidden' // Ensure rounded corners are visible
      }}
    />
  );
};

export default ActivityTable;



import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SubscriptionPlanSkeleton = () => {
    const isMobile = window.innerWidth <= 768;

    if (isMobile) {
        return (
            <div className='m-4'>
                <h1 className="mb-4 text-xl font-bold">
                    <Skeleton width={120} />
                </h1>
                <div>
                    <div className="mb-3 text-lg font-bold">
                        <Skeleton width={100} />
                    </div>
                    <div className="mb-3 text-lg font-bold">
                        <Skeleton width={100} />
                    </div>
                    <div className="mb-3 text-lg font-bold">
                        <Skeleton width={100} />
                    </div>
                </div>
                <div className="mt-6">
                    <h2 className="mb-4 text-lg font-bold">
                        <Skeleton width={150} height={24} />
                    </h2>
                    <div className="mb-4">
                        <Skeleton width={200} height={120} className="mb-2" />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='m-16'>
            <h1 className="mb-4 text-2xl font-bold">
                <Skeleton width={150} />
            </h1>
            <div className='flex'>
                <div>
                    <div className="mb-4 text-2xl font-bold">
                        <Skeleton width={150} />
                    </div>
                    <div className="mb-4 text-2xl font-bold">
                        <Skeleton width={150} />
                    </div>
                    <div className="mb-4 text-2xl font-bold">
                        <Skeleton width={150} />
                    </div>
                </div>
                <div className="p-8 mx-20">
                    <h1 className="mb-8 text-2xl font-bold">
                        <Skeleton width={200} height={30} />
                    </h1>
                    <div className="mb-6">
                        <Skeleton width={250} height={150} className="mb-2" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionPlanSkeleton;
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../AxiosConfig";
import MainLayout from "../layouts/MainLayout";
import { CheckCircleIcon, ClipboardIcon, TrashIcon } from "@heroicons/react/outline";

const QueryDetailsPage = () => {
    const { query_id } = useParams();
    const [queryDetails, setQueryDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showCopyPopup, setShowCopyPopup] = useState(false);
    const [copyStatus, setCopyStatus] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const analysisOrder = ["Coherence", "Orientation", "Nuance", "Support", "Ongoing", "Leadership", "Empathy"];
    const map = {
        Coherence: 'C',
        Orientation: 'O',
        Nuance: 'N',
        Support: 'S',
        Ongoing: 'O',
        Leadership: 'L',
        Empathy: 'E'
    };

    const handleDeleteClick = async () => {
        try {
            const response = await api.delete(`/delete-query-history/${query_id}`);
            if (response.status === 200) {
                window.location.href = '/query-history';
            } else {
                console.error('Failed to delete query history, server responded with:', response.status);
            }
        } catch (error) {
            console.error('Failed to delete query history', error);
        }
    };


    const handleCopyClick = () => {
        navigator.clipboard.writeText(queryDetails.query)
            .then(() => setCopyStatus(true))
            .catch(err => console.error('Failed to copy text:', err));
    };

    const mapConsoleValues = (numericValues) => {
        return analysisOrder.map(key => numericValues[key]);
    };

    const mapConsoleLabels = () => {
        return analysisOrder.map(key => map[key]);
    };

    const isOrange = (value) => {
        return value === 1 ? "orange" : "text-gray-300";
    };

    useEffect(() => {
        const fetchQueryDetails = async () => {
            try {
                const response = await api.get(`/query-details/${query_id}`);
                setQueryDetails(JSON.parse(response.data.Data));
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch query details:', error);
                setError('Failed to fetch query details');
                setLoading(false);
            }
        };

        if (copyStatus) {
            const timer = setTimeout(() => setCopyStatus(false), 2000);
            return () => clearTimeout(timer);
        }

        fetchQueryDetails();
    }, [query_id, copyStatus]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    if (!queryDetails) {
        return <p>No query details found</p>;
    }

    return (
        <MainLayout>
            <div className="py-2 sm:px-4">
                <div className="mt-8 lg:px-12 md:px-12 sm:px-4">
                    <div className="flex flex-row justify-between">
                        <p className="text-3xl font-bold">Your prompt</p>
                        <div>
                            <div className="flex flex-row gap-2 ml-auto">
                                <div
                                    className="relative"
                                    onMouseEnter={() => setShowCopyPopup(true)}
                                    onMouseLeave={() => setShowCopyPopup(false)}
                                >
                                    <div className="flex items-center">
                                        {copyStatus ? (
                                            <CheckCircleIcon className="w-6 h-6 text-green-500" />
                                        ) : (
                                            <ClipboardIcon className="w-6 h-6 text-gray-400 cursor-pointer" onClick={handleCopyClick} />
                                        )}
                                    </div>
                                    {showCopyPopup && !copyStatus && (
                                        <div className="absolute left-0 flex flex-row gap-2 px-2 py-2 mt-1 text-xs text-center text-white bg-gray-700 rounded-md shadow-lg top-full">
                                            Copy
                                        </div>
                                    )}
                                    {showCopyPopup && copyStatus && (
                                        <div className="absolute left-0 flex flex-row gap-2 px-2 py-2 mt-1 text-xs text-center text-white bg-gray-700 rounded-md shadow-lg top-full">
                                            Copied!
                                        </div>
                                    )}
                                </div>
                                <div
                                    className="relative"
                                    onMouseEnter={() => setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                >
                                    <TrashIcon className="w-6 h-6 text-gray-400 cursor-pointer" onClick={handleDeleteClick} />
                                    {showTooltip && (
                                        <div className="absolute left-0 flex flex-row gap-2 px-2 py-2 mt-1 text-xs text-center text-white bg-gray-700 rounded-md shadow-lg top-full">
                                            Delete
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                    <p className="mt-8 text-md">{queryDetails.query}</p>

                    <div>
                        <div className="mt-8 analysis-result">
                            <p style={{ fontFamily: "Red Hat Display" }} className="text-xl font-bold orange">CONSOLE Score</p>
                            <div className='grid grid-cols-1 gap-12 mt-8 md:grid-cols-2'>
                                <div className='px-8 py-6 bg-white border-2 border-gray-100 rounded-lg shadow-sm'>
                                    <div className="flex flex-row justify-center lg:gap-8 md:gap-6 sm:gap-6">
                                        {mapConsoleLabels().map((label, index) => (
                                            <div key={index} className="flex flex-col items-center gap-2">
                                                <p className={`lg:text-4xl md:text-xl sm:text-xl font-bold ${isOrange(mapConsoleValues(queryDetails.results[0].numericValues)[index])}`}>{label}</p>
                                                <p className={`text-4xl md:text-xl sm:text-xl ${isOrange(mapConsoleValues(queryDetails.results[0].numericValues)[index])}`}>{mapConsoleValues(queryDetails.results[0].numericValues)[index]}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className='flex items-center justify-center px-8 py-6 rounded-lg shadow-sm bg-light-orange'>
                                    <div className="flex flex-row items-center justify-center gap-8">
                                        <p className="font-bold orange lg:text-xl md:text-lg sm:text-base lg:w-48 md:w-40 sm:w-36">Aggregate CONSOLE Score: </p>
                                        <p className="font-bold orange lg:text-6xl md:text-4xl sm:text-4xl">{queryDetails.results[0].score}</p>
                                    </div>
                                </div>
                            </div>


                            <div className="grid grid-cols-1 gap-12 mt-8 md:grid-cols-2">
                                {analysisOrder.map((key) => (
                                    <div key={key} className="px-8 py-6 bg-white border-2 border-gray-100 rounded-lg shadow-sm">
                                        <div className="">
                                            <div className='flex flex-row items-center justify-between'>
                                                <div style={{ fontFamily: "Red Hat Display" }} className="text-lg">{key}</div>
                                                <div style={{ fontFamily: "Red Hat Display" }} className="text-lg orange">{queryDetails.results[0].numericValues[key]}</div>
                                            </div>
                                            <p className='mt-2 text-base'>{queryDetails.results[0].textValues[key]}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default QueryDetailsPage;

import LogoWhite from "../assets/images/logo-white.png";
import ContactUsButtonLight from "./ContactUsButtonLight";

function FooterOrange() {
    return (
        <div>
            <div id="footer" className="mt-12">
                <div className="flex lg:flex-row lg:justify-between lg:py-10 lg:px-24
                md:flex-col md:gap-4 md:py-6 md:px-12 md:justify-center
                sm:flex-col sm: gap-4 sm:py-4 sm:px-6 sm: justify-center
                align-middle items-center">
                    <div className="flex flex-col gap-4 
                    lg:justify-start lg:items-start
                    md:justify-center md:items-center
                    sm:justify-center sm:items-center">
                        <img src={LogoWhite} alt="Logo" className="lg:w-40 sm:w-24 md:w-36" />
                        <p className="lg:w-2/3 text-white text-md 
                        lg:justify-start lg:text-left
                        md:justify-center md:text-center md:w-full
                        sm:justify-center sm:text-center sm:w-full"
                        style={{ fontFamily: "Red Hat Display Regular" }}>
                            Bringing CONSOLE to crisis social media communications.
                        </p>
                    </div>
                    <div>
                        <ContactUsButtonLight />
                    </div>
                </div>
            </div>
            <div className="bg-red-100 py-4 flex flex-col lg:flex-row justify-between items-center px-6 lg:px-24">
                <p className="orange text-sm mb-2 lg:mb-0">© 2024 The Console-D Team. All Rights Reserved</p>
                <div className="flex gap-6">
                    <a href="https://socium.sg/terms-and-conditions" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="orange text-sm hover:underline">
                        Terms & Conditions
                    </a>
                    <a href="https://socium.sg/privacy-policy/" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="orange text-sm hover:underline">
                        Privacy Policy
                    </a>
                </div>
            </div>
        </div>
    );
}

export default FooterOrange;

import { useEffect, useState } from "react";
import { formatInTimeZone } from 'date-fns-tz';
import { ClipboardIcon, CheckCircleIcon, InformationCircleIcon, TrashIcon, BookmarkIcon } from "@heroicons/react/outline";
import api from "../AxiosConfig";
import { useNavigate } from "react-router-dom";

const QueryHistoryWidget = (queryHistory) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [showCopyPopup, setShowCopyPopup] = useState(false);
    const [showBookmarkPopup, setShowBookmarkPopup] = useState(false);
    const [copyStatus, setCopyStatus] = useState(false);
    const navigate = useNavigate();
    const query = queryHistory.queryHistory.query;
    const [showMore, setShowMore] = useState(false);
    const [isBookmarked, setIsBookmarked] = useState(false);
    const previewLength = 400;
    const previewText = query.length > previewLength ? query.substring(0, previewLength) + '...' : query;

    useEffect(() => {
        if (copyStatus) {
            const timer = setTimeout(() => setCopyStatus(false), 2000);
            return () => clearTimeout(timer);
        }

        if (queryHistory.queryHistory.is_bookmarked) {
            setIsBookmarked(true);
        }

    }, [copyStatus, queryHistory.queryHistory.is_bookmarked]);

    const handleReadMoreClick = () => {
        setShowMore(!showMore);
    };

    const formatDate = (isoString) => {
        const date = new Date(isoString);
    
        // Add 1 hour to convert to SGT
        date.setUTCHours(date.getUTCHours() + 1);
    
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const day = date.getUTCDate();
        const month = monthNames[date.getUTCMonth()];
        const year = date.getUTCFullYear();
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        const formattedHours = hours % 12 || 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    
        return `${day} ${month} ${year}, ${formattedHours}:${formattedMinutes} ${ampm} SGT`;
    };
    
    

    const handleDeleteClick = async () => {
        try {
            await api.delete(`/delete-query-history/${queryHistory.queryHistory._id.$oid}`);
            window.location.reload();
        } catch (error) {
            console.error('Failed to delete query history', error);
        }
    };

    const handleCopyClick = () => {
        navigator.clipboard.writeText(query)
            .then(() => setCopyStatus(true)) // Set copy status to true when copying
            .catch(err => console.error('Failed to copy text:', err));
    };

    const handleBookmarkClick = async () => {
        try {
            const response = await api.post(`/bookmark-query/${queryHistory.queryHistory._id.$oid}`);
            console.log("Response", response);
            setIsBookmarked(true);
        } catch (error) {
            console.error('Failed to delete query history', error);
        }
    };

    const handleRemoveBookmarkClick = async () => {
        try {
            const response = await api.post(`/remove-bookmark-query/${queryHistory.queryHistory._id.$oid}`);
            console.log("Response", response);
            setIsBookmarked(false);
        } catch (error) {
            console.error('Failed to delete query history', error);
        }
    }
    const handleDetailsClick = () => {
        navigate(`/query-details/${queryHistory.queryHistory._id.$oid}`);
    };
    return (
        <div className="flex flex-col gap-4 bg-white px-6 py-8 border-2 border-gray-100 rounded-lg">
            <div className="flex flex-col gap-4">
                <div className="flex flex-row align-middle justify-between items-center"> 
                    <div>
    
                        <p style={{ fontFamily: "Red Hat Display" }}>Your Prompt:</p>
                    </div> 
                    
                    <div className="flex flex-row gap-2 ml-auto">
                        <div
                            className="relative"
                            onMouseEnter={() => setShowCopyPopup(true)}
                            onMouseLeave={() => setShowCopyPopup(false)}
                        >
                            <div className="flex items-center">
                                {copyStatus ? (
                                    <CheckCircleIcon className="lg:h-6 lg:w-6 md:h-6 md:w-6 sm:h-4 sm:w-4 text-green-500" />
                                ) : (
                                    <ClipboardIcon className="lg:h-6 lg:w-6 md:h-6 md:w-6 sm:h-4 sm:w-4 text-gray-400 cursor-pointer" onClick={handleCopyClick} />
                                )}
                            </div>
                            {showCopyPopup && !copyStatus && (
                                <div className="absolute top-full left-0 text-center mt-1 px-2 py-2 text-xs bg-gray-700 text-white flex flex-row rounded-md shadow-lg gap-2">
                                    Copy
                                </div>
                            )}
                            {showCopyPopup && copyStatus && (
                                <div className="absolute top-full left-0 text-center mt-1 px-2 py-2 text-xs bg-gray-700 text-white flex flex-row rounded-md shadow-lg gap-2">
                                    Copied!
                                </div>
                            )}
                        </div>
                        <div
                            className="relative"
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                        >
                            <TrashIcon className="lg:h-6 lg:w-6 md:h-6 md:w-6 sm:h-4 sm:w-4 text-gray-400 cursor-pointer" onClick={handleDeleteClick} />
                            {showTooltip && (
                                <div className="absolute top-full left-0 text-center mt-1 px-2 py-2 text-xs bg-gray-700 text-white flex flex-row rounded-md shadow-lg gap-2">
                                    Delete
                                </div>
                            )}
                        </div>
                        <div
                            className="relative"
                            onMouseEnter={() => setShowBookmarkPopup(true)}
                            onMouseLeave={() => setShowBookmarkPopup(false)}
                        >
                            {isBookmarked ? <BookmarkIcon className="lg:h-6 lg:w-6 md:h-6 md:w-6 sm:h-4 sm:w-4 orange fill-orange-600 cursor-pointer" onClick={handleRemoveBookmarkClick} /> : <BookmarkIcon className="lg:h-6 lg:w-6 md:h-6 md:w-6 sm:h-4 sm:w-4 text-gray-400 cursor-pointer" onClick={handleBookmarkClick} />}
                            {showBookmarkPopup && !isBookmarked && (
                                <div className="absolute top-full left-0 text-center mt-1 px-2 py-2 text-xs bg-gray-700 text-white flex flex-row rounded-md shadow-lg gap-2">
                                    Bookmark
                                </div>
                            )}

                            {showBookmarkPopup && isBookmarked && (
                                <div className="absolute top-full left-0 text-center mt-1 px-2 py-2 bg-gray-700 text-white flex flex-row rounded-md shadow-lg gap-2 text-xs">
                                    Remove Bookmark
                                </div>
                            )}
                        </div>
                    </div>
                    
                    
                </div>
                <div className="flex flex-col gap-2 justify-start items-start">
                    <p>{showMore ? query : previewText}</p>
                    {query.length > previewLength && (
                        <button onClick={handleReadMoreClick}>
                            <p className="text-xs underline text-gray-400">{showMore ? 'Read less' : 'Read more'}</p>
                        </button>
                    )}
                </div>
            </div>
            <div className="lg:flex lg:flex-row md:flex md:flex-row sm:flex sm:flex-col align-middle justify-between items-center">
                <div className="flex flex-col gap-2">
                    <p className="orange text-md" style={{ fontFamily: "Red Hat Display" }}>
                        Aggregate CONSOLE Score: {queryHistory.queryHistory.results[0].score}
                    </p>
                    <p className="text-sm">Requested at {formatDate(queryHistory.queryHistory.createdAt.$date)}</p>
                </div>
                <div>
                    <button onClick={handleDetailsClick} className="bg-orange text-white rounded-md text-sm px-6 py-2 sm:mt-4">Details</button>
                </div>
            </div>
        </div>
    );
};

export default QueryHistoryWidget;

import React, { createContext, useState, useEffect } from 'react';
import api from '../AxiosConfig';

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  
  const fetchUserProfile = async () => {
    const token = localStorage.getItem('jwtToken');
    try {
      const response = await api.get('/user-profile', {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
      if (response['status'] === 200) {
        setUser(response.data['Data']);
      } else {
        setUser(null);
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, loading }}>
      
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;

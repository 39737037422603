import { useState } from "react";
import Logo from "../../widget/Logo";
import { forgetPasswordRequest } from "../../services/UserService";
import ContactUsButton from "../../widget/ContactUsButton";

const SendEmailPage = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        setEmail(e.target.value);
        setError('');
    };

    const submitEmail = async (e) => {
        e.preventDefault();
        if (!email) {
            setError('Please enter your email');
            return;
        }
        try {
            const response = await forgetPasswordRequest({
                'email': email
            });
            console.log(response);
            if(response.data['status_code'] === 200){
                setError('');
                window.location.href = '/sent-email';
            }else{
                setError(response.data['message']);
            }
        } catch (err) {
            console.error(err);
            setError('Failed to send email. Please try again.');
        }
    };

    return (
        <div className="page-container relative h-screen py-2 sm:px-4">
            <div className='lg:px-12 lg:py-6 sm:p-4 md:p-8 flex flex-row justify-between'>
                <Logo/>
            </div>
            {error && (
                <div className={`font-bold error-message ${error === 'Email sent!' ? 'generating' : 'active'}`}>
                    {error}
                </div>
            )}
            <div className="lg:px-12 mt-4 sm:px-4 md:px-6 flex flex-col justify-center">
                <div className="text-center">
                    <div className="title flex flex-col gap-2">
                        <p style={{ fontFamily: "Red Hat Display" }} className="text-bold lg:text-2xl md:text-xl sm:text-xl">
                            Forget your password?
                        </p>
                        <div className="subtitle lg:text-base mt-6 lg:px-20 sm:px-4 md:px-6 sm:text-sm md:text-sm">
                            <p>Don’t worry! We’ll send you an email to change it out.</p>
                        </div>
                        <div className='flex flex-col gap-4 mt-8'>
                            <p className='text-sm'>Registered email:</p>
                        {/* {error && <ErrorMessage message={error} />} */}
                            <div>
                                <input className="w-80 mb-2 px-4 py-2 border border-gray-300 rounded-md" type="email" placeholder="john@email.com" name="email" value={email} onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center mt-8">
                    <button className="sm:w-36 bg-orange px-4 py-2 text-white text-sm rounded-md" type="button" onClick={submitEmail}>Send email</button>
                </div>
            </div>
            <div className="background-image"></div>
        </div>
    )
}

export default SendEmailPage;

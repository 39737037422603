class User {
    constructor(email, password, firstname, lastname, role_name, created_at, updated_at, phone_number) {
        this.email = email;
        this.password = password;
        this.firstname = firstname;
        this.lastname = lastname;
        this.role_name = role_name;
        this.phone_number = phone_number;
        this.created_at = created_at;
        this.updated_at = updated_at;
    }
}

export default User;

class UserLoginRequestDTO {
    constructor(email, password) {
        this.email = email;
        this.password = password;
    }
}
export { UserLoginRequestDTO };

class UserRegisterRequestDTO {
    constructor(email, password, firstname, lastname, confirmPassword) {
        this.email = email;
        this.password = password;
        this.firstname = firstname;
        this.lastname = lastname;
        this.confirmPassword = confirmPassword;
    }
}

export { UserRegisterRequestDTO };

class UserChangePasswordRequestDTO{
    constructor(oldPassword, newPassword, confirmPassword){
        this.oldPassword = oldPassword;
        this.newPassword = newPassword;
        this.confirmPassword = confirmPassword;
    }
}

export { UserChangePasswordRequestDTO };
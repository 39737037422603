import './App.css';
import { createBrowserRouter, Navigate, Route, RouterProvider } from 'react-router-dom';
import RegisterPage from './pages/auth/RegisterPage';
import LoginPage from './pages/auth/LoginPage';
import SubscriberRoute from './route/SubscriberRoute';
import GuestRoute from './route/GuestRoute';
import { UserProvider } from './context/UserContext';
import PaymentPlanPage from './pages/payment/PaymentPlanPage';
import PaymentFormPage from './pages/payment/PaymentFormPage';
import AnalysisPage from './pages/AnalysisPage';
import FreeUserRoute from './route/FreeUserRoute';
import ProfilePage from './pages/profile/ProfilePage';
import QueryHistoryPage from './pages/QueryHistoryPage';
import { useEffect } from 'react';
import SendEmailPage from './pages/auth/SendEmailPage';
import AccountSettingsPage from './pages/profile/AccountSettingsPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import SentEmailConfirmationPage from './pages/auth/SentEmailConfirmationPage';
import SuccessForgetPasswordPage from './pages/auth/SuccessForgetPasswordPage';
import TokenExpiredPage from './pages/auth/TokenExpiredPage';
import ContactFormPage from './pages/ContactFormPage';
import UserRoute from './route/UserRoute';
import PaymentCancelledPage from './pages/payment/PaymentCancelledPage';
import SubscriptionPlanPage from './pages/profile/SubscriptionPlanPage';
import PaymentSuccessfulPage from './pages/payment/PaymentSuccessfulPage';
import QueryDetailsPage from './pages/QueryDetailsPage';
import BookmarkQueryPage from './pages/BookmarkQueryPage';
import AdminRoute from './route/AdminRoute';
import DashboardPage from './pages/admin-access/Dashboard';
import RegisterFreeAccountPage from './pages/admin-access/RegisterFreeAccountPage';
import UnverifiedUserRoute from './route/UnverifiedUserRoute';
import VerifyEmailRequestPage from './pages/auth/VerifyEmailRequestPage';
import VerifyEmailPage from './pages/auth/VerifyEmailPage';
import TokenEmailVerificationExpiredPage from './pages/auth/TokenEmailVerificationExpiredPage';
import ResendEmailVerificationPage from './pages/auth/ResendEmailVerificationPage';

const router = createBrowserRouter([
  {
    path: '/dashboard',
    element: (
      <AdminRoute>
        <DashboardPage />
      </AdminRoute>
    )
  },
  {
    path: '/register-subscriber',
    element: (
      <AdminRoute>
        <RegisterFreeAccountPage />
      </AdminRoute>
    )
  },
  {
    path: '/verify-account',
    element: (
      <UnverifiedUserRoute>
        <VerifyEmailRequestPage />
      </UnverifiedUserRoute>
    )
  },
  {
    path: '/payment-plan',
    element: (
      <FreeUserRoute>
        <PaymentPlanPage />
      </FreeUserRoute>
    )
  },

  {
    path: '/payment-request',
    element: (
      <FreeUserRoute>
        <PaymentFormPage />
      </FreeUserRoute>
    )
  },
  {
    path: '/',
    element: (
      <SubscriberRoute>
        <AnalysisPage />
      </SubscriberRoute>
    )
  },

  {
    path: '/contact-us',
    element: <ContactFormPage />
  },
  {
    path: '/bookmarked-queries',
    element:
      <SubscriberRoute>
        <BookmarkQueryPage />
      </SubscriberRoute>
  },
  {
    path: '/profile-page',
    element: (
      <UserRoute>
        <ProfilePage />
      </UserRoute>
    )
  },
  {
    path: '/subscription-plan',
    element: (
      <SubscriberRoute>
        <SubscriptionPlanPage />
      </SubscriberRoute>
    )
  },
  {
    path: '/payment-successful',
    element: (
      <SubscriberRoute>
        <PaymentSuccessfulPage />
      </SubscriberRoute>

    )
  },
  {
    path: '/payment-cancelled',
    element: (
      <FreeUserRoute>
        <PaymentCancelledPage />
      </FreeUserRoute>
    )
  },
  {
    path: '/account-settings',
    element: (
      <UserRoute>
        <AccountSettingsPage />
      </UserRoute>
    )
  },
  {
    path: '/query-history',
    element: (
      <SubscriberRoute>
        <QueryHistoryPage />
      </SubscriberRoute>
    )
  },
  {
    path: '/register',
    element: (
      <GuestRoute>
        <RegisterPage />
      </GuestRoute>
    ),
  },
  {
    path: '/request-reset-password',
    element: (
      <GuestRoute>
        <SendEmailPage />
      </GuestRoute>
    ),
  },
  {
    path: '/sent-email',
    element: (
      <GuestRoute>
        <SentEmailConfirmationPage />
      </GuestRoute>
    ),
  },
  {
    path: '/reset-password/:token',
    element: (
      <GuestRoute>
        <ResetPasswordPage />
      </GuestRoute>
    ),
  },
  {
    path: '/email-verification/:token',
    element: (
      <UnverifiedUserRoute>
        <VerifyEmailPage />
      </UnverifiedUserRoute>
    )
  },
  {
    path: "/query-details/:query_id",
    element: (
      <SubscriberRoute>
        <QueryDetailsPage />
      </SubscriberRoute>
    ),
  },
  {
    path: '/password-reset-confirmation',
    element: (
      <GuestRoute>
        <SuccessForgetPasswordPage />
      </GuestRoute>
    ),
  },
  {
    path: '/token-expired',
    element: (
      <TokenExpiredPage />
    ),
  },
  {
    path: '/token-email-verification-expired',
    element: (
      <TokenEmailVerificationExpiredPage />
    ),
  },
  {
    path: "/resend-email-verification",
    element: (
      <UnverifiedUserRoute>
        <ResendEmailVerificationPage />
      </UnverifiedUserRoute>
    )
  },
  {
    path: '/login',
    element: (
      <GuestRoute>
        <LoginPage />
      </GuestRoute>
    ),
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
]);

const App = () => {
  useEffect(() => {
    document.title = "Console-D"
  }, [])
  return (
    <UserProvider>

      <RouterProvider router={router} />

    </UserProvider>
  );
};

export default App;
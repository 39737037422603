import React from 'react';

const Sidebar = () => {
  return (
    <div className="w-64 h-screen bg-gray-800 text-white fixed top-0 left-0 flex flex-col p-4">
      <h2 className="text-xl font-bold mb-6">CONSOLE Admin Dashboard</h2>
      <ul className="space-y-4">
        <li><a href="#activities" className="hover:text-gray-400">User Activities</a></li>
        <li><a href="#about" className="hover:text-gray-400">Subscription Management</a></li>
        
      </ul>
    </div>
  );
};

export default Sidebar;



import HubspotContactForm from '../widget/HubspotContactForm';
import Logo from '../widget/Logo';

const ContactFormPage = () => {
  return (
      <div id="hubspot-form" className='page-container relative h-screen py-2 sm:px-4'>
        <div className='lg:px-12 lg:py-6 sm:p-4 md:p-8 flex flex-row justify-between'>
            <Logo/>
        </div>
            <div className="lg:px-12 lg:py-6 sm:p-4 md:p-8 flex flex-col justify-center items-center gap-4">
                <p className="text-3xl mt-auto" style={{ fontFamily: 'Red Hat Display' }}>Contact Us</p>
                <p className="text-sm lg:w-1/2 sm:w-3/4 text-center">We value your feedback and are here to assist you with any technical support or collaboration inquiries you may have. Whether you have questions about using CONSOLE-D, need help troubleshooting an issue, or are interested in partnering with us, please feel free to reach out.</p>
                <div className="mt-8 lg:w-2/4">
                    <HubspotContactForm 
                    region= "na1"
                    portalId="46888768"
                    formId="3a8008ef-f950-4ca6-b00e-54e4d16849e0"
                    />
                </div> 
            </div>
            
            <div className="background-image-contact"></div>
        
        </div>

    
    
  );
};

export default ContactFormPage;

import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import UserContext from '../context/UserContext';

// FreeUserRoute: Only allows access to users with the "free user" role, that is active
const FreeUserRoute = ({ children }) => {
  const { user, loading } = useContext(UserContext);
  
  if (loading) {
    return <div>Loading...</div>;
  }
  console.log("User", user)

  if (user && user.isActive === false){
    return <Navigate to="/resend-email-verification" replace />;
  }



  return user && user.role_name === "free user" && user.isActive === true ? children : <Navigate to="/" replace />;
};

export default FreeUserRoute;
import { FiInfo } from "react-icons/fi";

function NotSubscribedWidget(){
    return(
        <div className="bg-red-100 border border-red-400 text-red-700 text-xs px-4 py-3 rounded relative flex flex-row gap-2" role="alert">
            <FiInfo className="inline-block align-middle mr-2" />
            <span className="inline-block align-middle">You haven't subscribed to any plans. Subscribe <a href="/payment-plan"><b><u>here</u></b></a></span>
    </div>
    )

}
export default NotSubscribedWidget;
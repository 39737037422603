import { useEffect, useState } from "react";
import api from "../AxiosConfig";
import QueryHistoryWidget from "../widget/QueryHistoryWidget";
import NoQueryHistory from "../assets/images/no-queries-found.png";
import MainLayout from "../layouts/MainLayout";

const BookmarkQueryPage = () => {
    const [bookmark, setBookmark] = useState([]);
    useEffect(() => {
        const fetchBookmark = async () => {
            const response = await api.get('/get-bookmarks');
            var data = response.data.Data
            if (typeof data === 'string') {
                data = JSON.parse(data);
            }
            setBookmark(Array.isArray(data) ? data : []);
        };
        fetchBookmark();
    }, [bookmark]);

    return (
        <MainLayout>
            <div className="py-2 sm:px-4">
                <div className="mt-8 lg:px-12 md:px-12 sm:px-4">
                    <p className="text-3xl font-bold">Bookmarks</p>
                    <div className="mt-12 space-y-6">
                        {bookmark.length === 0 ? (
                            <div className="flex flex-col items-center justify-center gap-8 mt-24 mb-24 align-middle">
                                <img src={NoQueryHistory} alt="No query history found" className="w-36" />
                                <p className="orange text-bold">No bookmarks found</p>
                            </div>
                        ) : (
                            bookmark.map((item, index) => (
                                <QueryHistoryWidget key={index} queryHistory={item} />
                            ))
                        )}
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default BookmarkQueryPage;
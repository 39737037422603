import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import UserContext from '../context/UserContext';

const SubscriberRoute = ({ children }) => {
  const { user, loading } = useContext(UserContext);
  if (loading) {
    return <div></div>; 
  }
  if (!user) {
    return <Navigate to="/register" replace />;
  }
  if (user && user.role_name === 'admin') {
    return <Navigate to="/dashboard" replace />;
  }

  return user && user.role_name === 'subscriber' ? children : <Navigate to="/payment-plan" replace />;
};

export default SubscriberRoute;

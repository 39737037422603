import { useEffect, useState } from "react";
import Logo from "../../widget/Logo";
import { resetPassword } from "../../services/UserService";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { FiInfo } from "react-icons/fi";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import api from "../../AxiosConfig";
import ContactUsButton from "../../widget/ContactUsButton";
import FooterLight from "../../widget/FooterLight";


const ResetPasswordPage = () =>{
    const [error, setError] = useState('');
    const { token } = useParams();

    const [formData, setFormData] = useState({
        new_password: '',
        confirm_password: ''
    });
    
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);


    const handleChanges = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        setError('');
    };

    useEffect(() => {
        const checkToken = async () => {
            try {
                const response = await api.get(`/check-token/${token}`);
                if (response.data.status_code !== 200) {
                   window.location.href = '/token-expired';    
                }
            } catch (error) {
                window.location.href = '/token-expired';
            }
        };
        checkToken();
    }, [token]);


    const handleSaveChanges = async(e) => {
        e.preventDefault();
        const passwordRegex = /^(?=.*[A-Z])(?=.*\W).{6,}$/;
        if (!formData.new_password|| !formData.confirm_password) {
            setError('Please fill in all fields');
            return;
        }

        if (formData.new_password !== formData.confirm_password) {
            setError('New password and confirm password do not match');
            return;
        }

        if (!passwordRegex.test(formData.new_password)) {
            setError('Password must be at least 6 characters long, contain at least one special character, and one capital letter.');
            return;
        }

        try {
            setErrorMessage("Updating password...");
            const response = await resetPassword(token, formData);
            if (response["status_code"]  === 200) {
                setTimeout(() => {
                    window.location.href = '/password-reset-confirmation';
                    setError('');
                }, 2000);
            } else if(response["message"] === "Token has expired"){
                window.location.href = '/token-expired';
            }
            else {
                setError(response["message"]);
            }
        } catch (error) {
            console.error("Error updating profile:", error);
        } finally {

        }
    };
   
    const ErrorMessage = ({ message }) => {
        return (
            <div className="w-64 bg-red-100 border border-red-400 text-red-700 text-xs px-4 py-3 rounded relative" role="alert">
                <FiInfo className="inline-block align-middle mr-2" />
                <span className="inline-block align-middle">{message}</span>
            </div>
        );
    };
    
    return (
        <div>
            <div className="page-container relative h-screen py-2 sm:px-4">
            <div className='lg:px-12 lg:py-6 sm:p-4 md:p-8 flex flex-row justify-between'>
                <Logo/>
            </div>
            <div className="lg:px-12 mt-4 sm:px-4 md:px-6 flex flex-col justify-center">
                <form onSubmit={handleSaveChanges}>
                    <div className="text-center">
                        <div className="title flex flex-col gap-2">
                            <p   style={{ fontFamily: "Red Hat Display" }} className="text-bold lg:text-2xl md:text-xl sm:text-xl">
                                Reset Password
                            </p>
                            <div className="flex flex-col items-center justify-center">
                                <div className="mt-4">
                                    {error && <ErrorMessage message={error} />}
                                </div>
                                <div className="flex flex-col gap-4 mt-8">
                                            <p>New password:</p>
                                            <div className="relative flex items-center">
                                                <input
                                                id="new_password"
                                                className="w-full mb-2 px-4 py-2 border border-gray-300 rounded-md pr-10" 
                                                type={showNewPassword ? "text" : "password"} 
                                                placeholder="********"
                                                name="new_password"
                                                value={formData.new_password}
                                                onChange={handleChanges}
                                                />
                                                <button
                                                type="button"
                                                className="absolute inset-y-0 right-0 flex items-center mr-2 mb-2 orange "
                                                onClick={() => setShowNewPassword(!showNewPassword)}
                                                >
                                                {showNewPassword ? <EyeOffIcon className="h-5 w-5" /> : <EyeIcon className="h-5 w-5 " />}
                                                </button>
                                            </div>
                                        </div>
                                    
                                    <div className="flex flex-col gap-4 mt-4">
                                        <p>Confirm new password:</p>
                                        <div className="relative flex items-center">
                                            <input
                                            id="confirm_password"
                                            className="w-full mb-2 px-4 py-2 border border-gray-300 rounded-md pr-10" 
                                            type={showConfirmPassword ? "text" : "password"} 
                                            placeholder="********"
                                            name="confirm_password"
                                            value={formData.confirm_password}
                                            onChange={handleChanges}
                                            />
                                            <button
                                            type="button"
                                            className="absolute inset-y-0 right-0 flex items-center mr-2 mb-2 orange "
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            >
                                            {showConfirmPassword ? <EyeOffIcon className="h-5 w-5" /> : <EyeIcon className="h-5 w-5 " />}
                                            </button>
                                        </div>
                                    </div>
                            </div>
                            
                        </div>
                    </div>
                
                    <div className="flex justify-center mt-12">
                        <button className="sm:w-36 bg-orange px-4 py-2 text-white text-sm rounded-md" type="submit">Reset password</button>
                    </div>
                </form>
                
            </div>
            </div>
            <div className="background-image">
                <FooterLight/>
            </div>
        </div>
        
    )

}

export default ResetPasswordPage;
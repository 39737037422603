import api from './../AxiosConfig';

export const GetSubscription = async () => {
    try {
        const response = await api.get('/get-subscription-types'); 
        if (response.status === 200) {
            return response.data; 
        } else {
            throw new Error(`Failed to fetch subscription types: ${response.statusText}`);
        }
    } catch (error) {
        console.error('Error fetching subscription types:', error.message);
        throw error; 
    }
}

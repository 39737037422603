import '../../pages/fonts.css';
import '../../pages/css/Main.css';
import { useState } from 'react';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { UserLoginRequestDTO } from '../../models/User';
import { login} from '../../services/UserService';
import { FiInfo } from 'react-icons/fi';
import BaseOutput from '../../models/output/BaseOutput';
import Logo from '../../widget/Logo';
import ContactUsButton from '../../widget/ContactUsButton';
import FooterLight from '../../widget/FooterLight';

const LoginPage = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState(new UserLoginRequestDTO()); 
    const [errorMessage, setErrorMessage] = useState('');

    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setErrorMessage('');
    };
    const saveToken = (token) => {
        localStorage.setItem('jwtToken', token);
    };
    
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.email || !formData.password) {
            setErrorMessage('All fields must be filled out.');
            return;
        }

        const response = await login(formData);
        const output = new BaseOutput(response.message,response.status_code);
        saveToken(response["access_token"]);
        if(output.status_code === 200){
            window.location.href = '/';
        }else{
            setErrorMessage(output.message);
        }
    };
    
    const ErrorMessage = ({ message }) => {
        return (
            <div className="bg-red-100 border border-red-400 text-red-700 text-xs px-4 py-3 rounded relative" role="alert">
                <FiInfo className="inline-block align-middle mr-2" />
                <span className="inline-block align-middle">{message}</span>
            </div>
        );
    };
    return (
        <div>
            <div className="page-container relative py-2 sm:px-4">
                <div className='lg:px-12 lg:py-6 sm:p-4 md:p-8 flex flex-row justify-between'>
                    <Logo/>
                </div>
                    <div className="flex flex-col sm:gap-6 lg:mt-6 md:flex-row md:items-center lg:px-32  sm:px-6  md:px-12">
                        <div className='flex flex-col gap-2 lg:w-2/3 md:mt-56'>
                            <p className="text-3xl mt-auto" style={{ fontFamily: 'Red Hat Display' }}>
                                Welcome back to <span className="orange underline">Console-D</span>
                            </p>
                            <p className="text-base md:w-2/3">
                                Please log in to unlock the smart crisis communication features.
                            </p>
                        </div>
                        
                    <div className="md:w-2/3 md:mt-0 sm:px-8 md:px-16">
                        <form className="lg:mt-12 sm:mt-2 flex flex-col gap-4" onSubmit={handleSubmit}>
                            <div className='flex flex-col gap-4'>
                                <p style={{ fontFamily: 'Red Hat Display' }} className='text-2xl'>Login</p>
                                <p className='text-sm orange underline'><a href="/register">Haven't created an account? <span className="orange underline">Register here</span></a></p>
                            </div>
                            <div className='flex flex-col gap-2'>
                                {errorMessage && <ErrorMessage message={errorMessage} />}
                                <div className='flex flex-col gap-2 mt-2'>
                                    <p className='text-sm' >Email</p>
                                    <input className="w-full mb-2 px-4 py-2 border border-gray-300 rounded-md" type="email" placeholder="john@email.com" name="email" value={formData.email} onChange={handleChange}/>
                                </div>
                                <div className='flex flex-col gap-4'>
                                    <p className='text-sm'>Password</p>
                                    <div className="relative flex items-center">
                                        <input
                                        id="password"
                                        className="w-full mb-2 px-4 py-2 border border-gray-300 rounded-md pr-10" 
                                        type={showPassword ? "text" : "password"} 
                                        placeholder="********"
                                        name="password"
                                        value={formData.password} onChange={handleChange}
                                        />
                                        <button
                                        type="button"
                                        className="absolute inset-y-0 right-0 flex items-center mr-2 mb-2 orange "
                                        onClick={() => setShowPassword(!showPassword)}
                                        >
                                        {showPassword ? <EyeOffIcon className="h-5 w-5" /> : <EyeIcon className="h-5 w-5 " />}
                                        </button>
                                    </div>
                                </div>
                                <a href="/request-reset-password"><p  style={{ fontFamily: 'Red Hat Display' }} className='orange underline text-sm'>Forgot password?</p></a>
                                <div className="flex justify-center mt-6 sm:mb-12">
                                    <button className="sm:w-36 bg-orange px-4 py-2 text-white rounded-md" type="submit">Login</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="background-image">
                <FooterLight/>
            </div>
            
            
        </div>
        
    );
}

export default LoginPage;
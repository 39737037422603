import React, { useContext, useState } from "react";
import UserContext from "../../context/UserContext";
import "../../pages/css/Main.css";
import { changePassword } from "../../services/UserService";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { FiInfo } from "react-icons/fi";
import LogoutButton from "../../widget/LogoutButton";
import NotSubscribedWidget from "../../widget/NotSubscribedWidget";
import MainLayout from "../../layouts/MainLayout";

const AccountSettingsPage = () => {
    const { user, loading } = useContext(UserContext);
    const [formData, setFormData] = useState({
        old_password: '',
        new_password: '',
        confirm_password: ''
    });

    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleChanges = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        setError('');
        setErrorMessage('');
    };

    const handleSaveChanges = async (e) => {
        e.preventDefault();
        const passwordRegex = /^(?=.*[A-Z])(?=.*\W).{6,}$/;
        if (!formData.old_password || !formData.new_password || !formData.confirm_password) {
            setErrorMessage('Please fill in all fields');
            return;
        }

        if (formData.new_password !== formData.confirm_password) {
            setErrorMessage('New password and confirm password do not match');
            return;
        }

        if (!passwordRegex.test(formData.new_password)) {
            setErrorMessage('Password must be at least 6 characters long, contain at least one special character, and one capital letter.');
            return;
        }

        try {
            setIsLoading(true);
            setError("Updating password...");
            const response = await changePassword(formData);
            // console.log(response);
            if (response["status_code"] === 200) {
                setTimeout(() => {
                    window.location.reload();
                    setError('');
                }, 2000);
            } else {
                setError(response["message"]);
            }
        } catch (error) {
            console.error("Error updating profile:", error);
        } finally {
            setIsLoading(false);
        }



    };

    const ErrorMessage = ({ message }) => {
        return (
            <div className="relative flex flex-row gap-2 px-4 py-3 text-xs text-red-700 bg-red-100 border border-red-400 rounded" role="alert">
                <FiInfo className="inline-block mr-2 align-middle" />
                <span className="inline-block align-middle">{message}</span>
            </div>
        );
    };

    return (
        <MainLayout>
            <div className="py-2 sm:px-4">
                {error && (
                    <div className={`font-bold error-message ${error === 'Updating password...' ? 'generating' : 'active'}`}>
                        {error}
                    </div>
                )}
                <div className="lg:px-12 lg:mt-12 md:mt-8 sm:px-4 md:px-6">
                    <p style={{ fontFamily: "Red Hat Display" }} className="mb-4 lg:text-3xl md:text-2xl sm:text-2xl">Account</p>
                    {user.role_name === 'free user' && <NotSubscribedWidget />}
                    <div>
                        <div className="lg:flex lg:flex-row md:flex md:flex-row sm:flex sm:flex-col lg:gap-24 md:gap-16 lg:mt-8 md:mt-8">
                            <div className="gap-4 lg:flex lg:flex-col md:flex md:flex-col sm:flex sm:flex-row">
                                <a href="/profile-page"><p className="font-bold text-gray-400 lg:text-lg md:text-md sm:text-sm">Profile</p></a>
                                <a href="/account-settings"><p className="font-bold lg:text-lg md:text-md sm:text-sm orange">Account Settings</p></a>
                                {user.role_name === 'subscriber' && <a href="/subscription-plan"><p className="font-bold text-gray-400 lg:text-lg md:text-md sm:text-sm">Subscription Plan</p></a>}
                            </div>
                            <div className="flex flex-col gap-8">
                                <div className="flex flex-col gap-4">
                                    <p style={{ fontFamily: "Red Hat Display" }} className="lg:text-2xl md:text-xl sm:text-lg sm:mt-8">Logout</p>
                                    <p>You can logout here.</p>
                                    <div className="px-1">
                                        <LogoutButton />
                                    </div>
                                </div>
                                <div>
                                    <p style={{ fontFamily: "Red Hat Display" }} className="lg:text-2xl md:text-xl sm:text-lg">Change Password</p>
                                    <form className="flex flex-col gap-4 mt-8 mb-4">
                                        {errorMessage && <ErrorMessage message={errorMessage} />}
                                        <div className="flex flex-col gap-4">
                                            <p>Current password:</p>
                                            <div className="relative flex items-center">
                                                <input
                                                    id="old_password"
                                                    className="w-full px-4 py-2 pr-10 mb-2 border border-gray-300 rounded-md"
                                                    type={showPassword ? "text" : "password"}
                                                    placeholder="********"
                                                    name="old_password"
                                                    value={formData.old_password}
                                                    onChange={handleChanges}
                                                />
                                                <button
                                                    type="button"
                                                    className="absolute inset-y-0 right-0 flex items-center mb-2 mr-2 orange "
                                                    onClick={() => setShowPassword(!showPassword)}
                                                >
                                                    {showPassword ? <EyeOffIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5 " />}
                                                </button>
                                            </div>
                                        </div>

                                        <div className="flex flex-col gap-4">
                                            <p>New password:</p>
                                            <div className="relative flex items-center">
                                                <input
                                                    id="new_password"
                                                    className="w-full px-4 py-2 pr-10 mb-2 border border-gray-300 rounded-md"
                                                    type={showNewPassword ? "text" : "password"}
                                                    placeholder="********"
                                                    name="new_password"
                                                    value={formData.new_password}
                                                    onChange={handleChanges}
                                                />
                                                <button
                                                    type="button"
                                                    className="absolute inset-y-0 right-0 flex items-center mb-2 mr-2 orange "
                                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                                >
                                                    {showNewPassword ? <EyeOffIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5 " />}
                                                </button>
                                            </div>
                                        </div>

                                        <div className="flex flex-col gap-4">
                                            <p>Confirm new password:</p>
                                            <div className="relative flex items-center">
                                                <input
                                                    id="confirm_password"
                                                    className="w-full px-4 py-2 pr-10 mb-2 border border-gray-300 rounded-md"
                                                    type={showConfirmPassword ? "text" : "password"}
                                                    placeholder="********"
                                                    name="confirm_password"
                                                    value={formData.confirm_password}
                                                    onChange={handleChanges}
                                                />
                                                <button
                                                    type="button"
                                                    className="absolute inset-y-0 right-0 flex items-center mb-2 mr-2 orange "
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                >
                                                    {showConfirmPassword ? <EyeOffIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5 " />}
                                                </button>
                                            </div>
                                        </div>
                                        <button
                                            onClick={handleSaveChanges}
                                            className={`bg-orange text-white text-sm rounded-md py-2 px-4 mt-2 w-40 ${isLoading ? 'cursor-not-allowed' : ''}`}
                                            disabled={isLoading}
                                        >
                                            Change password
                                        </button>

                                    </form>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default AccountSettingsPage;

import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../context/UserContext';
import api from './../AxiosConfig';

const LogoutButton = () => {
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const response = await api.post('/logout', {}, { withCredentials: true });
      if (response.status === 200) {
        setUser(null);
        localStorage.removeItem('jwtToken');
        navigate('/login');
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };


  return (
    <button onClick={handleLogout}
      className='bg-[#eb5527] hover:bg-[#ff782a] duration-200 text-white rounded-md text-sm lg:py-2 lg:px-4 sm:px-2 sm:py-2 md:px-2 md:py-2 font-bold'>
      Logout
    </button>
  );
};

export default LogoutButton;

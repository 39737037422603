import Logo from "../../widget/Logo";
import TokenExpired from "../../assets/images/token-expired.png";
import FooterLight from "../../widget/FooterLight";

const TokenEmailVerificationExpiredPage = () =>{
    return(
        <div>
             <div className="page-container relative h-screen py-2 sm:px-4">
            <div className='lg:px-12 lg:py-6 sm:p-4 md:p-8 flex flex-row justify-between'>
                <Logo/>
            </div>
            <div className="lg:px-12 mt-12 sm:px-4 md:px-6 flex flex-col justify-center items-center text-center">
                
                <img src={TokenExpired} alt="Email Sent" className="w-40 sm:w-24 lg:w-36"/>
                <p style={{ fontFamily: "Red Hat Display" }} className="mt-4 text-bold lg:text-2xl md:text-xl sm:text-xl">
                    Your link has expired.
                </p>

                <div className="subtitle lg:text-base mt-6 lg:px-20 sm:px-4 md:px-6 sm:text-sm md:text-sm">
                    <p>You can request <a href="/resend-email-verificationt"><span className="orange underline">request email verification</span></a> again.</p>
                </div>
                
            </div>


            </div>
            <div className="background-image">
                <FooterLight/>
            </div>
        </div>
       
    )
}

export default TokenEmailVerificationExpiredPage;
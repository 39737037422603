import React, { useContext } from 'react';
import LogoImage from '../assets/images/logo.png';
import UserContext, { UserProvider } from '../context/UserContext'; // Adjust the path as needed
import UserWidget from './UserWidget';
import ContactUsButton from './ContactUsButton';


const Navbar = () => {
  const { user } = useContext(UserContext);

  const navigateToAnalysis = () => {
    window.location.href = '/';
  }
  return (
    <div className="flex items-center justify-between p-4 bg-white">
      <div className="flex items-center">
        <button>
          <img onClick={navigateToAnalysis} src={LogoImage} alt="Logo" className="w-40 sm:w-24 lg:w-36" />
        </button>
      </div>
      <div className="flex flex-row gap-2 text-lg">
        <button>
          <UserProvider>
            <UserWidget />
          </UserProvider>
        </button>
      </div>

    </div>
  );
};

export default Navbar;

import React, { useEffect } from 'react';
import api from '../../AxiosConfig';
import Logo from '../../widget/Logo';
import FooterLight from '../../widget/FooterLight';
import CheckMark from '../../assets/images/check-mark.png';

const PaymentSuccessfulPage = () => {

  const handleButtonClick = () => {
    window.location.href = '/';
  };

  useEffect(() => {
    const loadGoogleAnalytics = () => {
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=G-Z10ME8VFN7`;
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'G-Z10ME8VFN7');

        if (window.gtag) {
          window.gtag('event', 'payment_successfull',
            {
              event_label: "Payment Successfull",
            }
          );
        }
      };
    };

    loadGoogleAnalytics();
  }, []); 

  return (
    <div>
      <div className="page-container relative h-screen py-2 sm:px-4">
        <div className='lg:px-12 lg:py-6 sm:p-4 md:p-8 flex flex-row justify-between'>
          <Logo/>
        </div>
            
        <div className="lg:px-12 mt-12 sm:px-4 md:px-6 flex flex-col justify-center items-center text-center">
          <img src={CheckMark} alt="Payment Successful" className="w-40 sm:w-24 lg:w-36"/>
          <p style={{ fontFamily: "Red Hat Display" }} className="mt-4 text-bold lg:text-2xl md:text-xl sm:text-xl">
            Your payment is successful!
          </p>

          <button className="mt-12 sm:w-48 bg-orange px-4 py-3 text-white rounded-md" onClick={handleButtonClick}>
            <b>Access CONSOLE</b>
          </button>
              
        </div>
      </div>
      <div className="background-image">
        <FooterLight/>
      </div>
    </div>
  );
};

export default PaymentSuccessfulPage;

import React from 'react';
const ContactUsButtonLight = () => {


  const redirect = () => {
    window.location.href = '/contact-us';
  }
  return (
    <button onClick={redirect} 
        className='bg-red-100 orange rounded-md text-sm lg:px-10 sm:px-8 sm:py-4 md:px-8 md:py-4 font-bold'>
      Contact Us
    </button>
  );
};

export default ContactUsButtonLight;


import LogoImage from '../assets/images/logo.png'
const Logo = () => {
    return (
        <div>
                <a href='/'><img src={LogoImage} alt="Logo" className="mb-4 w-40 sm:w-24 lg:w-36"/></a>
                
            </div>
    )
};

export default Logo;
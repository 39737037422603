import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import UserContext from '../context/UserContext';

const AdminRoute = ({ children }) => {
  const { user, loading } = useContext(UserContext);
  if (loading) {
    return <div></div>; 
  }
  if (!user ) {
    return <Navigate to="/register" replace />;
  }
  return user && user.role_name === 'admin' ? children : <Navigate to="/" replace />;
};

export default AdminRoute;

import React, { useState } from 'react';
import "../pages/css/Main.css";
import { AnalysisService } from '../services/AnalysisService';
import { DocumentSearchIcon } from '@heroicons/react/outline';
import MainLayout from '../layouts/MainLayout';

const AnalysisPage = () => {
    const [inputText, setInputText] = useState('');
    const [error, setError] = useState('');
    const [analysisResult, setAnalysisResult] = useState(null);
    const [showSocialMediaMessage, setShowSocialMediaMessage] = useState(false);
    const analysisOrder = ["Coherence", "Orientation", "Nuance", "Support", "Ongoing", "Leadership", "Empathy"];
    const map = {
        Coherence: 'C',
        Orientation: 'O',
        Nuance: 'N',
        Support: 'S',
        Ongoing: 'O',
        Leadership: 'L',
        Empathy: 'E'
    };
    const mapConsoleValues = (numericValues) => {
        return analysisOrder.map(key => numericValues[key]);
    };

    const mapConsoleLabels = () => {
        return analysisOrder.map(key => map[key]);
    };

    const analyzeText = async () => {
        if (inputText.trim() === '') {
            setError('Text area cannot be empty');
            setTimeout(() => setError(''), 3000);
            return;
        }
        setAnalysisResult(null);
        setError('Analyzing...');
        try {
            const result = await AnalysisService(inputText);
            if (result) {
                setAnalysisResult(result);
                setError('');
            } else {
                setError("Looks like we're experiencing trouble. Try refreshing!");
            }
        } catch (error) {
            setError(error.message);
        }
    };
    const navigateQueryHistory = () => {
        window.location.href = '/query-history';
    };
    const isOrange = (value) => {
        return value === 1 ? "orange" : "text-gray-300";
    };

    return (
        <MainLayout>
            <div className="py-2 sm:px-4">
                {error && (
                    <div className={`font-bold error-message ${error === 'Analyzing...' ? 'generating' : 'active'}`}>
                        {error}
                    </div>
                )}
                <div className="mt-4 lg:px-12 sm:px-4 md:px-6">
                    <div className="text-center">
                        <div className="flex flex-col gap-2 title">
                            <p style={{ fontFamily: "Red Hat Display" }} className="text-bold lg:text-2xl md:text-xl sm:text-xl">
                                Welcome to <span className="orange">CONSOLE-D</span>
                            </p>
                            <p style={{ fontFamily: "Red Hat Display" }} className="text-xl text-bold">
                                The dashboard to bring CONSOLE to your crisis communications.
                            </p>
                        </div>
                        <div className="mt-4 subtitle lg:text-base lg:px-20 sm:px-4 md:px-6 sm:text-sm md:text-sm">
                            <p>
                                <i>
                                    The <b>CONSOLE (Coherence, Orientation, Nuance, Support, Ongoing, Leadership, Empathy) framework</b> by Tan et al. (2019) proposes a framework to help organisations break bad news effectively to stakeholders during crisis through social media. For more information about the framework, please see: <a href="https://www.sciencedirect.com/science/article/abs/pii/S0363811117303697" className="underline orange">Breaking Bad News with CONSOLE</a>
                                </i>
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-row justify-start gap-6 mt-12">
                        <div className="flex flex-row justify-center gap-8 bg-white border-2 border-gray-100 rounded-lg choice lg:py-2 lg:px-4 lg:w-56 md:w-52 md:py-2 md:px-4 sm:w-44 sm:px-2 sm:py-2">
                            <button className="orange lg:text-base md:text-sm sm:text-xs"><b>Manual</b></button>
                            <div
                                className="relative"
                                onMouseEnter={() => setShowSocialMediaMessage(true)}
                                onMouseLeave={() => setShowSocialMediaMessage(false)}
                            >
                                <button className="text-gray-300 cursor-pointer lg:text-base md:text-sm sm:text-xs">
                                    Social Media
                                </button>
                                {showSocialMediaMessage && (
                                    <div className="absolute z-10 p-2 mt-2 text-sm border-2 border-orange-500 rounded-md bg-orange-50 orange w-52">
                                        <p>This feature is currently not available, and will be rolled out in Q4 of 2024.</p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-row items-center gap-2 px-4 py-2 rounded-lg bg-light-orange" onClick={navigateQueryHistory}>
                            <DocumentSearchIcon className="w-6 h-6 sm:h-4 sm:w-4 md:h-4 md:w-4 orange" />
                            <button className='font-bold orange lg:text-base md:text-sm sm:text-xs'>Query History</button>
                        </div>
                    </div>

                    <div className="mx-auto mt-4">
                        <textarea
                            className="w-full p-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent"
                            rows="8"
                            maxLength="3000"
                            placeholder="Enter text here..."
                            value={inputText}
                            onChange={(e) => setInputText(e.target.value)}
                        />
                    </div>
                    <div className="flex flex-col items-center">
                        <button onClick={analyzeText} className="px-8 py-2 mt-4 text-white duration-200 rounded-md bg-orange w-36 hover:bg-[#ff782a]">
                            Analyze
                        </button>
                    </div>

                    {analysisResult && (
                        <div className="mt-8 analysis-result">
                            <p style={{ fontFamily: "Red Hat Display" }} className="text-xl font-bold orange">CONSOLE Score</p>
                            <div className='grid grid-cols-1 gap-12 mt-8 md:grid-cols-2'>
                                <div className='px-8 py-6 bg-white border-2 border-gray-100 rounded-lg shadow-sm'>
                                    <div className="flex flex-row justify-center lg:gap-8 md:gap-6 sm:gap-6">
                                        {mapConsoleLabels().map((label, index) => (
                                            <div key={index} className="flex flex-col items-center gap-2">
                                                <p className={`lg:text-4xl md:text-xl sm:text-xl font-bold ${isOrange(mapConsoleValues(analysisResult[0].numericValues)[index])}`}>{label}</p>
                                                <p className={`text-4xl md:text-xl sm:text-xl ${isOrange(mapConsoleValues(analysisResult[0].numericValues)[index])}`}>{mapConsoleValues(analysisResult[0].numericValues)[index]}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className='flex items-center justify-center px-8 py-6 rounded-lg shadow-sm bg-light-orange'>
                                    <div className="flex flex-row items-center justify-center gap-8">
                                        <p className="font-bold orange lg:text-xl md:text-lg sm:text-base lg:w-48 md:w-40 sm:w-36">Aggregate CONSOLE Score: </p>
                                        <p className="font-bold orange lg:text-6xl md:text-4xl sm:text-4xl">{analysisResult[0].score}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-1 gap-12 mt-8 md:grid-cols-2">
                                {analysisOrder.map((key) => (
                                    <div key={key} className="px-8 py-6 bg-white border-2 border-gray-100 rounded-lg shadow-sm">
                                        <div className="">
                                            <div className='flex flex-row items-center justify-between'>
                                                <div style={{ fontFamily: "Red Hat Display" }} className="text-lg">{key}</div>
                                                <div style={{ fontFamily: "Red Hat Display" }} className="text-lg orange">{analysisResult[0].numericValues[key]}</div>
                                            </div>
                                            <p className='mt-2 text-base'>{analysisResult[0].textValues[key]}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </MainLayout>
    );
};

export default AnalysisPage;